import React from 'react'

const Footer = () => {
  return (
    <div>
     <footer className="page-footer">
  <p className="mb-0">Copyright © 2023. All right reserved.</p>
</footer>
 
    </div>
  )
}

export default Footer
